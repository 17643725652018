var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.active
      ? _c("div", { staticClass: "modal" }, [
          _c(
            "div",
            {
              staticClass: "modal-wrap",
              class: { "content-center": _vm.center }
            },
            [
              _c("span", {
                staticClass: "modal-close",
                on: {
                  click: function($event) {
                    return _vm.$emit("onClose")
                  }
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "container py-4" }, [
                _c(
                  "div",
                  { staticClass: "modal-dialog" },
                  [
                    _c(
                      "mega-card",
                      { attrs: { title: _vm.title, menu: _vm.menu } },
                      [_vm._t("default")],
                      2
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }