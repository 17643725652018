<template>
  <transition name="fade">
    <div class="modal" v-if="active">

      <div class="modal-wrap" :class="{'content-center': center}">

        <span class="modal-close" @click="$emit('onClose')"></span>

        <div class="container py-4">
          <div class="modal-dialog">
            <mega-card :title="title" :menu="menu">
              <slot></slot>
            </mega-card>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    props: {
      title: [String, Number],
      center: {
        type: Boolean,
        default: false
      },
      active: {
        type: Boolean,
        default: false
      },
      menu: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {

      }
    }
  }
</script>
